/* Font face defined */
@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.8ab28b30.eot);
  src:  url(/static/media/icomoon.8ab28b30.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.a95504ff.ttf) format('truetype'),
    url(/static/media/icomoon.4558a22f.woff) format('woff'),
    url(/static/media/icomoon.567bb958.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Font face defined */
@font-face {
  font-family: 'icomoon-simulations';
  src:  url(/static/media/icomoon.5c6177e2.eot);
  src:  url(/static/media/icomoon.5c6177e2.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.366b8877.ttf) format('truetype'),
    url(/static/media/icomoon.6e50013d.woff) format('woff'),
    url(/static/media/icomoon.7d0b8fdc.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-y: auto !important; /* show scrollbar even if material ui popover as open */
}

pre {
  white-space: pre-wrap;
}

/*** SCROLLBAR ***/

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:vertical {
  width: 8px; /* This is more usable for users trying to click it. */
}
::-webkit-scrollbar:horizontal {
  height: 8px; /* This is more usable for users trying to click it. */
}
::-webkit-scrollbar:hover {
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb {
  /* EXACT color of Mac OS scrollbars */
  background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}

*.no-scrollbar {
  -ms-overflow-style: none; /* hide scrollbar on ie */
  scrollbar-width: none; /* hide scrollbar on firefox */
}
*.no-scrollbar::-webkit-scrollbar {
  /* hide scrollbar on chrome */
  width: 0 !important;
}

/*** FONT ICONS ***/

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cool-availability:before {
  content: "\E900";
}
.icon-cool:before {
  content: "\E901";
}
.icon-cooling:before {
  content: "\E902";
}
.icon-heat-availability:before {
  content: "\E903";
}
.icon-heat:before {
  content: "\E904";
}
.icon-heating:before {
  content: "\E905";
}
.icon-infiltration:before {
  content: "\E906";
}
.icon-internal-mass:before {
  content: "\E907";
}
.icon-light:before {
  content: "\E908";
}
.icon-mechanical-vent:before {
  content: "\E909";
}
.icon-oa:before {
  content: "\E90A";
}
.icon-people:before {
  content: "\E90B";
}
.icon-plug:before {
  content: "\E90C";
}
.icon-ventilation-old:before {
  content: "\E90D";
}
.icon-ventilation:before {
  content: "\E90E";
}
.icon-water:before {
  content: "\E90F";
}

/* Font icons defined */
[class^="icon-simulations-"], [class*=" icon-simulations-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-simulations' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-simulations-empty:before {
  content: "\E900";
}
.icon-simulations-running:before {
  content: "\E901";
}

/* ToggleInput */
.o-autocomplete {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0;
  border: 0;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  position: relative;
  min-width: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  vertical-align: top;
  font-size: '8px';
}
.o-autocomplete label {
  -webkit-transform: translate(0, 1.5px) scale(0.75);
          transform: translate(0, 1.5px) scale(0.75);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  padding-left: '10px';
}
.o-autocomplete > div > div > div {
  padding: 0;
}

@keyframes rotating {
    100% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}

@-webkit-keyframes rotating {
    100% {
        -webkit-transform: rotate(180deg);
    }
}

@keyframes rotatingOplus {
    80%, 100% {
        -webkit-transform: rotate(270deg);
                transform: rotate(270deg);
    }
}

@-webkit-keyframes rotatingOplus {
    80%, 100% {
        -webkit-transform: rotate(270deg);
    }
}
