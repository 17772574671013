/* Font face defined */
@font-face {
  font-family: 'icomoon';
  src:  url('assets/icomoon/activityZoneGroups/icomoon.eot?pn7iq0');
  src:  url('assets/icomoon/activityZoneGroups/icomoon.eot?pn7iq0#iefix') format('embedded-opentype'),
    url('assets/icomoon/activityZoneGroups/icomoon.ttf?pn7iq0') format('truetype'),
    url('assets/icomoon/activityZoneGroups/icomoon.woff?pn7iq0') format('woff'),
    url('assets/icomoon/activityZoneGroups/icomoon.svg?pn7iq0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Font face defined */
@font-face {
  font-family: 'icomoon-simulations';
  src:  url('assets/icomoon/simulations/icomoon.eot?pn7iq0');
  src:  url('assets/icomoon/simulations/icomoon.eot?pn7iq0#iefix') format('embedded-opentype'),
    url('assets/icomoon/simulations/icomoon.ttf?pn7iq0') format('truetype'),
    url('assets/icomoon/simulations/icomoon.woff?pn7iq0') format('woff'),
    url('assets/icomoon/simulations/icomoon.svg?pn7iq0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-y: auto !important; /* show scrollbar even if material ui popover as open */
}

pre {
  white-space: pre-wrap;
}

/*** SCROLLBAR ***/

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}

::-webkit-scrollbar:vertical {
  width: 8px; /* This is more usable for users trying to click it. */
}
::-webkit-scrollbar:horizontal {
  height: 8px; /* This is more usable for users trying to click it. */
}
::-webkit-scrollbar:hover {
  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb {
  /* EXACT color of Mac OS scrollbars */
  background: rgba(0,0,0,0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}

*.no-scrollbar {
  -ms-overflow-style: none; /* hide scrollbar on ie */
  scrollbar-width: none; /* hide scrollbar on firefox */
}
*.no-scrollbar::-webkit-scrollbar {
  /* hide scrollbar on chrome */
  width: 0 !important;
}

/*** FONT ICONS ***/

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cool-availability:before {
  content: "\e900";
}
.icon-cool:before {
  content: "\e901";
}
.icon-cooling:before {
  content: "\e902";
}
.icon-heat-availability:before {
  content: "\e903";
}
.icon-heat:before {
  content: "\e904";
}
.icon-heating:before {
  content: "\e905";
}
.icon-infiltration:before {
  content: "\e906";
}
.icon-internal-mass:before {
  content: "\e907";
}
.icon-light:before {
  content: "\e908";
}
.icon-mechanical-vent:before {
  content: "\e909";
}
.icon-oa:before {
  content: "\e90a";
}
.icon-people:before {
  content: "\e90b";
}
.icon-plug:before {
  content: "\e90c";
}
.icon-ventilation-old:before {
  content: "\e90d";
}
.icon-ventilation:before {
  content: "\e90e";
}
.icon-water:before {
  content: "\e90f";
}

/* Font icons defined */
[class^="icon-simulations-"], [class*=" icon-simulations-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-simulations' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-simulations-empty:before {
  content: "\e900";
}
.icon-simulations-running:before {
  content: "\e901";
}

/* ToggleInput */
.o-autocomplete {
  flex: 1;
  margin: 0;
  border: 0;
  display: flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  font-size: '8px';
}
.o-autocomplete label {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  padding-left: '10px';
}
.o-autocomplete > div > div > div {
  padding: 0;
}

@keyframes rotating {
    100% {
        transform: rotate(180deg);
    }
}

@-webkit-keyframes rotating {
    100% {
        -webkit-transform: rotate(180deg);
    }
}

@keyframes rotatingOplus {
    80%, 100% {
        transform: rotate(270deg);
    }
}

@-webkit-keyframes rotatingOplus {
    80%, 100% {
        -webkit-transform: rotate(270deg);
    }
}